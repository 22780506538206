/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
// react-router components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputIcon from "@mui/icons-material/Input";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleStatisticsCard from "examples/Cards/StatisticsCards/SimpleStatisticsCard";
import DataTable from "examples/Tables/DataTable";

import TableRequest from "./components/TableRequest";

// services
import { KpiService } from "@sharecode/common/services/KpiService";

// data
import useTable from "./data/dataTableData";

import { CREATE_OFFER } from "@sharecode/common/routes/constants";

import FilterButton from "examples/FilterButton";
import PageListing from "components/PagesListing";

import { IS_MANAGER_BRAND_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { useRole } from "routes/ProtectedRoutes";

function ClientList(props) {
  const [listKpi, setListKpi] = useState([]);
  const tableData = useTable();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sizeSelected, setSizeSelected] = useState();

  const role = useRole();
  useEffect(() => {
    KpiService.getKpiClientList().then((r) => {
      setListKpi(r);
    });
  }, []);

  const handleChangeSize = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", e.value);
    searchParams.set("search", "");
    setSizeSelected({ value: e.value, label: e.label });
    setSearchParams(searchParams);
  };

  const searchCtn = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", searchParams.get("size") ? searchParams.get("size") : 10);
    searchParams.set("search", e.target.value);
    setSearchParams(searchParams);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={0}>
        <SuiBox mb={3}>
          <FilterButton />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Nb de contacts", fontWeight: "medium" }}
                count={listKpi.nb_contact}
                icon={{ component: "person" }}
                direction="left"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "% clients fid", fontWeight: "medium" }}
                count={listKpi.fid_clients}
                icon={{ component: "percent" }}
                direction="left"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "evo periode prec", fontWeight: "medium" }}
                count={listKpi.evo_periode_prec}
                icon={{ component: "emoji_people" }}
                direction="left"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "age moyen", fontWeight: "medium" }}
                count={listKpi.avg_age}
                icon={{ component: "bar_chart" }}
                direction="left"
              />
            </Grid>
          </Grid>
        </SuiBox>
        <Card>
          <SuiBox p={2}>
            <Grid container spacing={3} display="flex">
              <Grid item md={6}>
                <SuiBox>
                  <SuiTypography variant="h6" fontWeight="medium">
                    Listing des clients souhaitant des offres exclusives
                  </SuiTypography>
                </SuiBox>
              </Grid>
              {role == IS_MANAGER_BRAND_VALUE && props.listClients ? (
                <Grid item md={4} display="flex" justifyContent="flex-end">
                  <Link to={CREATE_OFFER}>
                    <SuiButton variant="gradient" color="info">
                      <Icon>local_offer</Icon> &nbsp; Créer une offre
                    </SuiButton>
                  </Link>
                </Grid>
              ) : (
                ""
              )}
              <Grid item display="flex" position="absolute" right={0} marginRight={2}>
                <SuiBox display="flex">
                  <SuiButton color="dark">
                    <Icon>exit_to_app</Icon>&nbsp; Exporter&nbsp;
                  </SuiButton>
                </SuiBox>
              </Grid>
              {/* <Grid item>
                <IconButton color="dark">
                  <FilterAltIcon />
                </IconButton>
              </Grid> */}
            </Grid>
          </SuiBox>
          <SuiBox p={2} display="flex">
            <SuiBox display="flex">
              <SuiBox justifyContent="left">
                <SuiSelect
                  placeholder="Elements par page"
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "50", label: "50" },
                    { value: "100", label: "100" },
                    { value: "200", label: "200" },
                  ]}
                  onChange={(e) => handleChangeSize(e)}
                  value={{
                    value: searchParams.get("size") ? searchParams.get("size") : "10",
                    label: searchParams.get("size") ? searchParams.get("size") : "10",
                  }}
                />
              </SuiBox>
              <div
                style={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "16px",
                }}
              >
                Total: {props.listClients.count}
              </div>
            </SuiBox>
            <SuiBox style={{ flex: "50%" }}>
              <SuiBox justifyContent="right" style={{ float: "right" }}>
                <SuiInput
                  placeholder="Recherche..."
                  icon={{
                    component: "search",
                    direction: "right",
                  }}
                  onKeyUp={(e) => searchCtn(e)}
                />
              </SuiBox>
            </SuiBox>
          </SuiBox>
          <TableRequest />

          {/* <DataTable table={tableData} canSearch /> */}
        </Card>
        <PageListing data={props.listClients} />
      </SuiBox>
    </DashboardLayout>
  );
}
function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ClientList);
