/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
// Soft UI Dashboard PRO React example components
import SimpleStatisticsCard from "examples/Cards/StatisticsCards/SimpleStatisticsCard";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import BarChart from "examples/Charts/BarCharts/VerticalBarChart";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import FilterButton from "examples/BrandFilterButton";
// icons
import AdbIcon from "@mui/icons-material/Adb";
import AppleIcon from "@mui/icons-material/Apple";
// colors
import colors from "assets/theme/base/colors";
// assets
import clip from "assets/images/clip-sign-up.png";

import { useRole } from "routes/ProtectedRoutes";
// Data fetching hooks
import useViewsChartData from "./data/viewsChartData";
import useClicksChartData from "./data/clicksChartData";
import useFavoritesChartData from "./data/favoritesChartData";
import useCpcChartData from "./data/cpcChartData";

// services
import { KpiService } from "@sharecode/common/services/KpiService";
// Custom components
import { DatePeriodFilter } from "components/Filters/DatePeriodFilter";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCampaigns,
  fetchCampaignStats,
  fetchCampaignAds,
  fetchDailyStats,
  fetchMonthlyStats,
  fetchWeeklyStats,
} from "@sharecode/common/redux/actions/campaignsActions";
import { updateToken } from "@sharecode/common/redux/actions/authActions";

function DashboardBrand() {
  const dispatch = useDispatch();

  const [formattedToken, setFormattedToken] = useState("");
  const campaigns = useSelector((state) => state.campaignReducer.campaigns);
  const campaignAds = useSelector((state) => state.campaignReducer.campaignAds);
  const allCampaignAds = useSelector((state) => state.campaignReducer.campaignAdsById);

  // single campaign selected to view details
  const selectedCampaignId = useSelector((state) => state.campaignReducer.selectedCampaignId);
  // single campaign ads
  const selectedCampaignAds = useSelector((state) => state.campaignReducer.campaignAds.data);

  // access the token to update it on UAT
  const token = useSelector((state) => state?.campaignReducer?.token);

  // Function to calculate spent amount for a single campaign
  function calculateSpentAmount(campaign) {
    return (campaign.clickBid / 100) * campaign.clickCurrent;
  }
  // Function to calculate total number of clicks for a single campaign
  function calculateTotalClicks(campaign) {
    return campaign.clickCurrent;
  }

  // Calculate total spent amount across all campaigns
  const totalSpentAmount = campaigns?.data?.reduce((total, campaign) => {
    return total + calculateSpentAmount(campaign);
  }, 0);
  // Calculate total number of clicks across all campaigns
  const totalClicks = campaigns?.data?.reduce((total, campaign) => {
    return total + calculateTotalClicks(campaign);
  }, 0);

  // Update formattedToken whenever token changes
  useEffect(() => {
    const newFormattedToken = token?.split(" ")[1];
    if (newFormattedToken) {
      setFormattedToken(newFormattedToken);
    }
  }, [token]);
  // access the user id following successful login
  const userId = useSelector((state) => state?.auth.user);

  // update token on UAT
  useEffect(() => {
    if (userId && formattedToken && !window.location.hostname.includes("dash.app-check.fr")) {
      dispatch(updateToken(userId, formattedToken));
    }
  }, [formattedToken, userId, dispatch]);

  const [loading, setLoading] = useState(false);

  // colors
  const { gradients } = colors;
  const [listKpi, setListKpi] = useState([]);
  const role = useRole();

  //date filters states
  const periodicityOptions = [
    { value: "daily", label: "Jour" },
    { value: "weekly", label: "Semaine" },
    { value: "monthly", label: "Mois" },
  ];
  const [periodicity, setPeriodicity] = useState(periodicityOptions[0].value);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Initialize selected campaigns and stories IDs
  const [selectedCampaignIds, setSelectedCampaignIds] = useState(
    selectedCampaignId ? [selectedCampaignId] : []
  );
  const [selectedStoryIds, setSelectedStoryIds] = useState(
    selectedCampaignAds ? selectedCampaignAds?.map((ad) => ad.id) : []
  );
  const [selectedStory, setSelectedStory] = useState(null);
  // find the selected campaign object based on the selected campaign ID
  const [selectedCampaign, setSelectedCampaign] = useState(
    selectedCampaignId
      ? campaigns.data.find((campaign) => campaign.id === selectedCampaignId)
      : null
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Set mode based on whether a campaign ID is selected
  const [mode, setMode] = useState(selectedCampaignId ? "single" : "all");

  // Function to show the Snackbar with a custom message
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // fetching campaigns
  useEffect(() => {
    setLoading(true);
    dispatch(fetchCampaigns())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching campaigns:", error);
        setLoading(false);
      });
  }, []);
  // fetching campaigns stats
  useEffect(() => {
    if (campaigns.data && campaigns.data.length > 0) {
      setLoading(true);
      Promise.all(campaigns.data.map((campaign) => dispatch(fetchCampaignStats(campaign.id))))
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching campaign stats:", error);
          setLoading(false);
        });
    }
  }, [campaigns.data, dispatch]);
  // fetching campaigns ads
  useEffect(() => {
    if (campaigns.data && campaigns.data.length > 0) {
      setLoading(true);
      Promise.all(campaigns.data.map((campaign) => dispatch(fetchCampaignAds(campaign.id))))
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching campaign ads:", error);
          setLoading(false);
        });
    }
  }, [campaigns.data, dispatch]);

  // fetching daily stats
  useEffect(() => {
    dispatch(fetchDailyStats())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily stats:", error);
        setLoading(false);
      });
  }, []);
  // fetching monthly stats
  useEffect(() => {
    dispatch(fetchMonthlyStats())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching monthly stats:", error);
        setLoading(false);
      });
  }, []);
  // fetching weekly stats
  useEffect(() => {
    dispatch(fetchWeeklyStats())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching weekly stats:", error);
        setLoading(false);
      });
  }, []);

  // Fetch KPI data
  useEffect(() => {
    if (isRoleDefined()) {
      KpiService.getKpiDashboard()
        .then((r) => {
          setListKpi(r);
        })
        .catch((error) => {
          console.error("Error fetching KPI Data:", error);
        });
    }
  }, []);
  // Set start and end date for the current week
  useEffect(() => {
    const currentDate = new Date();
    const firstDay = currentDate.getDate() - currentDate.getDay() + 1; // First day is the day of the month - the day of the week
    const lastDay = firstDay + 6; // last day is the first day + 6

    const firstDayDate = new Date(currentDate.setDate(firstDay)).toISOString().split("T")[0];
    const lastDayDate = new Date(currentDate.setDate(lastDay)).toISOString().split("T")[0];

    setStartDate(firstDayDate);
    setEndDate(lastDayDate);
  }, []);

  // Campaign options based on the selected mode
  const campaignOptions = campaigns?.data?.map((campaign) => ({
    value: campaign.id,
    label: campaign.campaignName,
  }));

  // Story options based on the selected campaign
  const storyOptions = Object.values(allCampaignAds)
    .flatMap((campaign) => campaign.data)
    .filter(
      (ad) => selectedCampaignIds.length === 0 || selectedCampaignIds.includes(ad.ads_campaign_id)
    )
    .map((ad) => ({
      value: ad.id,
      label: `${ad.ads_name} (${ad.ads_campaign_order_nb})`,
    }));

  // handle campaign & story selection
  const handleCampaignSelect = useCallback((selectedOption) => {
    setSelectedCampaignIds(selectedOption ? [selectedOption?.value] : []);
    setSelectedCampaign(selectedOption);
  }, []);

  useEffect(() => {
    if (mode === "single" && campaigns?.data?.length > 0) {
      handleCampaignSelect({
        value: selectedCampaign.id,
        label: selectedCampaign.campaignName,
      });
    }
  }, []);

  const handleStorySelect = useCallback((selectedOption) => {
    setSelectedStoryIds(selectedOption ? [selectedOption?.value] : []);
    setSelectedStory(selectedOption);
  }, []);

  const handleClearPeriodFilter = useCallback(() => {
    setStartDate("");
    setEndDate("");
  }, []);

  const handleClearCampaignAndStoryFilter = useCallback(() => {
    setSelectedCampaignIds([]);
    setSelectedStoryIds([]);
  }, []);

  const handlePeriodicityChange = useCallback((option) => {
    // Check if the selected option is disabled
    const isOptionDisabled = periodicityOptions.find((o) => o.value === option.value && o.disabled);
    if (!isOptionDisabled) {
      setPeriodicity(option.value);
    } else {
      showSnackbar("Option disponible prochainement.");
    }
  }, []);

  const clicksChartData = useClicksChartData(
    periodicity,
    selectedCampaignIds,
    selectedStoryIds,
    startDate,
    endDate
  );

  const viewsChartData = useViewsChartData(
    periodicity,
    selectedCampaignIds,
    selectedStoryIds,
    startDate,
    endDate
  );

  const favoritesChartData = useFavoritesChartData(
    periodicity,
    selectedCampaignIds,
    selectedStoryIds,
    startDate,
    endDate
  );

  const cpcChartData = useCpcChartData(selectedCampaignIds);

  // combined chart for sales Vs budget
  // const combinedChartData = {
  //   labels: spentAmountChartData.labels,
  //   datasets: [
  //     {
  //       label: "Montant Dépensé",
  //       data: spentAmountChartData.datasets[0].data,
  //       color: "orchid",
  //     },
  //     {
  //       label: "Ventes Générées",
  //       data: salesChartData.datasets[0].data,
  //       color: "salsa",
  //     },
  //   ],
  // };

  const isRoleDefined = () => {
    // For some reason, null & undefined are stored as strings
    return role && role != "null" && role != "undefined";
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {isRoleDefined() ? (
        <SuiBox py={0}>
          {/* Section des SimpleStatisticsCard */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Campagnes Actives", fontWeight: "medium" }}
                count={campaigns?.data?.filter((campaign) => !campaign.has_ended).length}
                icon={{ component: "campaign_icon" }}
                direction="left"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Montant dépensé", fontWeight: "medium" }}
                count={(totalSpentAmount || 0).toFixed(2) + "€"}
                icon={{ component: "euro_symbol" }}
                direction="left"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Nb de clicks", fontWeight: "medium" }}
                count={totalClicks}
                icon={{ component: "touch_app" }}
                direction="left"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{
                  text: "Ventes générées",
                  fontWeight: "small",
                }}
                count="Prochainement"
                icon={{ component: "construction" }}
                direction="left"
              />
            </Grid>
          </Grid>
          {/* End of Section des SimpleStatisticsCard */}

          {/* Section de la présentation des filtres */}
          <SuiBox mt={4}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card
                  style={{
                    background: `linear-gradient(45deg, ${gradients.info.main} 30%, ${gradients.info.state} 90%)`,
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      align="center"
                      style={{ color: "white" }}
                    >
                      Sélectionnez la période d’analyse
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    background: `linear-gradient(45deg, ${gradients.info.main} 30%, ${gradients.info.state} 90%)`,
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      align="center"
                      style={{ color: "white" }}
                    >
                      Sélectionnez la campagne à analyser
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </SuiBox>
          {/* End of Section de la présentation des filtres */}
          {/* Section des filtres */}
          <SuiBox mt={4} mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePeriodFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  handleClearPeriodFilter={handleClearPeriodFilter}
                  hideFilterButton={true}
                />
                <SuiBox mt={2}>
                  <SuiSelect
                    placeholder="Trier par"
                    options={periodicityOptions}
                    value={periodicityOptions.find((option) => option.value === periodicity)}
                    onChange={handlePeriodicityChange}
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FilterButton
                  onCampaignSelect={handleCampaignSelect}
                  onStorySelect={handleStorySelect}
                  campaignOptions={campaignOptions}
                  storyOptions={storyOptions}
                  handleClearCampaignAndStoryFilter={handleClearCampaignAndStoryFilter}
                  selectedCampaignIds={selectedCampaignIds}
                  selectedStoryIds={selectedStoryIds}
                  selectedStory={selectedStory}
                  selectedCampaign={selectedCampaign}
                />
              </Grid>
            </Grid>
          </SuiBox>

          {/* charts data */}
          <SuiBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <GradientLineChart title="Nombre de vues" chart={viewsChartData} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <BarChart title="Nombre de Clics" chart={clicksChartData} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ProgressLineChart
                  title="Ajouts en Favoris"
                  color="primary"
                  chart={{ labels: favoritesChartData.labels, data: favoritesChartData.data }}
                  icon="favorite"
                  count={favoritesChartData.totalCount}
                  height="19.125rem"
                  // progress={
                  //   favoritesChartData.periodicity
                  //     ? favoritesChartData.periodicityProgress
                  //     : favoritesChartData.overallProgress
                  // }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <BarChart title="Coût par clic" chart={cpcChartData} />
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* <HorizontalBarChart
                  title="Montant Dépensé Vs Ventes Générées"
                  chart={combinedChartData}
                /> */}
              </Grid>
            </Grid>
          </SuiBox>
        </SuiBox>
      ) : (
        <SuiBox display="flex" alignItems="center" py={1} pt={2}>
          <Grid item xs={12} lg={6}>
            <SuiBox component="img" src={clip} width="100%" mt={0} />
          </Grid>
          <Grid item xs={12}>
            <SuiTypography variant="h5" fontWeight="medium" style={{ marginTop: 30 }}>
              Vous n'avez pas les droits suffisants pour accéder aux fonctionnalités de ce site.
            </SuiTypography>
            <SuiTypography variant="h6" fontWeight="medium" style={{ marginTop: 15 }}>
              Contactez votre supérieur pour qu'un rôle vous soit attitré.
            </SuiTypography>
            <SuiTypography variant="h6" fontWeight="medium" style={{ marginTop: 15 }}>
              Vous pouvez d'ores et déjà utiliser l'application mobile, disponible sur Android{" "}
              <AdbIcon fontSize="medium"></AdbIcon> et iOS <AppleIcon fontSize="medium"></AppleIcon>
              .
            </SuiTypography>
          </Grid>
        </SuiBox>
      )}
    </>
  );
}

export default DashboardBrand;
