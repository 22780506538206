import * as types from './actionTypes';
import AdService from '../../services/AdService';

// Fetch Ads
export const fetchAds = () => (dispatch) => {
  dispatch({ type: types.FETCH_ADS_REQUEST });
  return AdService.getAds()
    .then((response) => {
      dispatch({
        type: types.FETCH_ADS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.error('Error fetching Ads:', error);
      console.log('Error details:', error.response);
      dispatch({ type: types.FETCH_ADS_FAILURE, payload: error });
    });
};

// Create Ad
export const createAd = (adData, campaignId) => (dispatch) => {
  dispatch({ type: types.CREATE_AD_REQUEST });
  return AdService.createAd(adData, campaignId)
    .then((response) => {
      dispatch({
        type: types.CREATE_AD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: types.CREATE_AD_FAILURE, payload: error });
    });
};

// Fetch Ad Details
export const fetchAdDetails = (adId) => (dispatch) => {
  dispatch({ type: types.FETCH_AD_DETAILS_REQUEST });
  return AdService.getAdDetails(adId)
    .then((response) => {
      dispatch({
        type: types.FETCH_AD_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_AD_DETAILS_FAILURE,
        payload: error,
      });
    });
};

// Update Ad
export const updateAd = (adId, adData) => async (dispatch) => {
  dispatch({ type: types.UPDATE_AD_REQUEST });

  try {
    const response = await AdService.editAd(adId, adData);
    if (response.status >= 200 && response.status < 300) {
      dispatch({
        type: types.UPDATE_AD_SUCCESS,
        payload: response.data,
      });
    } else {
      throw new Error('Failed to update ad');
    }
  } catch (error) {
    dispatch({
      type: types.UPDATE_AD_FAILURE,
      payload: error.message || 'Unknown error',
    });
  }
};

// delete Ad
export const deleteAd = (adId) => (dispatch) => {
  dispatch({ type: types.DELETE_AD_REQUEST });
  return AdService.deleteAd(adId)
    .then((response) => {
      dispatch({
        type: types.DELETE_AD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: types.DELETE_AD_FAILURE, payload: error });
    });
};
