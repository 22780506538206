import { Card } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams, useSearchParams } from "react-router-dom";
import PageListing from "components/PagesListing";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import TableRequest from "./components/TableRequest";
import { getSingleUserTickets } from "@sharecode/common/redux/actions/tickets";
import { getSingleClient } from "@sharecode/common/redux/actions/listClients";
import TicketUpdate from "examples/Drawers/ticketUpdate";
import moment from "moment";
import { ExportButton } from "components/ExportButton";
import { SizeSelectorFilter } from "components/Filters/SizeSelectorFilter";
import { SectorSelectFilter } from "components/Filters/SectorSelectFilter";
import { getBrandCategories } from "@sharecode/common/redux/actions/brand";
import { DatePeriodFilter } from "components/Filters/DatePeriodFilter";
import { setSnackbar } from "@sharecode/common/redux/actions/snack";
import { exportTable } from "@sharecode/common/utils/helpers";
import { URL_POST_USER } from "@sharecode/common/services/constantApi";

function UserTickets(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tickets, setTickets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userFullname, setUserFullname] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [loadingBrandOptions, setLoadingBrandOptions] = useState(false);

  const [sizeSelected, setSizeSelected] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [brandSelected, setBrandSelected] = useState("");

  const dispatch = useDispatch();

  const { uid } = useParams();

  const filterParams = (obj, itemToRemove) => {
    const result = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== "" && obj[key] !== null && key !== itemToRemove) {
        result[key] = obj[key];
      }
    });
    return result;
  };

  const generalSearchParamsObject = {
    page: 1,
    size: searchParams.get("size") || 10,
    area: searchParams.get("area") || "",
    start_date: searchParams.get("start_date") || "",
    end_date: searchParams.get("end_date") || "",
  };

  const filteredSearchParams = filterParams(generalSearchParamsObject);

  useEffect(() => {
    const brand = searchParams.get("area") || "";
    const size = searchParams.get("size") || "10";
    const initialDate = searchParams.get("start_date") || "";
    const finalDate = searchParams.get("end_date") || "";

    setStartDate(initialDate);
    setEndDate(finalDate);
    setSizeSelected({ value: size, label: size });

    if (brand.length > 1) {
      const matchingOption = brandOptions.find((option) => option.value === brand);
      if (matchingOption) {
        setBrandSelected({ value: brand, label: matchingOption.label });
      } else {
        setBrandSelected("");
      }
    } else {
      setBrandSelected("");
    }
  }, [searchParams, brandOptions]);

  const handleSizeChange = (e) => {
    setSearchParams({
      ...filteredSearchParams,
      page: 1,
      size: e.value,
    });
    setSizeSelected({ value: e.value, label: e.label });
  };

  const handleChangeBrand = (e) => {
    if (!e) {
      searchParams.delete("area");
      setSearchParams(searchParams);
      setBrandSelected("");
      return;
    }

    setSearchParams({
      ...filteredSearchParams,
      page: 1,
      area: e.value,
      search: searchParams.get("search") || "",
    });
    setBrandSelected({ value: e.value, label: e.label });
  };

  const handlePeriodFilter = () => {
    if (startDate && endDate) {
      let formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      let formattedEndDate = moment(endDate).format("YYYY-MM-DD");

      if (moment(formattedStartDate).isSameOrBefore(formattedEndDate)) {
        searchParams.set("start_date", formattedStartDate);
        searchParams.set("end_date", formattedEndDate);
        return setSearchParams(searchParams);
      }
    }

    dispatch(
      setSnackbar(
        true,
        "error",
        "error",
        "La date de début doit être inférieure ou égale à la date de fin."
      )
    );
    searchParams.delete("start_date");
    searchParams.delete("end_date");
    setSearchParams(searchParams);

    setStartDate("");
    setEndDate("");
  };

  const handleClearPeriodFilter = () => {
    setSearchParams({ ...filteredSearchParams, start_date: "", end_date: "" });
  };

  useEffect(() => {
    setLoading(true);
    new Promise(async (resolve, reject) => {
      const page = searchParams.get("page") || 1;
      const size = searchParams.get("size") || 10;
      const search = searchParams.get("search") || null;
      const startDate = searchParams.get("start_date") || null;
      const endDate = searchParams.get("end_date") || null;
      const area = searchParams.get("area") || null;
      const userId = uid;
      const unassigned = searchParams.get("unassigned") || null;
      const paperTickets = searchParams.get("paper_tickets") || null;

      dispatch(
        getSingleUserTickets(
          page,
          size,
          search,
          startDate,
          endDate,
          area,
          userId,
          unassigned,
          paperTickets
        )
      )
        .then((response) => {
          resolve(response);
          setTickets(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });

      dispatch(getSingleClient(userId))
        .then((response) => {
          setUserFullname(`${response.first_name} ${response.last_name}`);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [window.location.search]);

  useEffect(() => {
    setLoadingBrandOptions(true);

    dispatch(getBrandCategories(null, null, null, true))
      .then((response) => {
        let newArray = response.map((item) => {
          return {
            value: item.code,
            label: item.value,
          };
        });
        setBrandOptions(newArray);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingBrandOptions(false);
      });
  }, []);

  const handleUserTicketsExport = () => {
    setLoadingExport(true);

    const keys = ["start_date", "end_date", "area"];

    const paramsValues = {};

    keys.forEach((key) => {
      const value = searchParams.get(key);
      if (value) {
        paramsValues[key] = value;
      }
    });

    const exportUrl = `${URL_POST_USER}${uid}/tickets/export/`;
    const exportFilename = `user-${uid}-tickets.xlsx`;

    exportTable({ url: exportUrl, fileName: exportFilename }, paramsValues)
      .then((response) => {})
      .catch((error) => {
        dispatch(setSnackbar(true, "error", "error", "No entry to export"));
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={2}>
          <Card>
            <SuiBox mb={4}>
              <SuiBox
                p={2}
                gap={2}
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                display="flex"
                alignItems="baseline"
                justifyContent="space-between"
              >
                <SuiTypography variant="h6" fontWeight="medium">
                  Gestion des tickets de {userFullname}
                </SuiTypography>
                <ExportButton
                  disabled={tickets?.count === 0}
                  handleExport={handleUserTicketsExport}
                  loading={loadingExport}
                />
              </SuiBox>

              <SuiBox
                display="flex"
                gap={2}
                justifyContent="space-between"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                p={2}
              >
                <SizeSelectorFilter
                  sizeValue={sizeSelected}
                  handleSizeChange={handleSizeChange}
                  totalCount={tickets?.count}
                />
              </SuiBox>

              <SuiBox
                display="flex"
                gap={2}
                p={2}
                sx={{ flexDirection: { xs: "column", md: "row" }, flexShrink: 0 }}
              >
                <SectorSelectFilter
                  brandOptions={brandOptions}
                  loading={loadingBrandOptions}
                  handleChangeBrand={handleChangeBrand}
                  brandValue={brandSelected}
                />
              </SuiBox>

              <SuiBox px={2} display="flex">
                <DatePeriodFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  handlePeriodFilter={handlePeriodFilter}
                  handleClearPeriodFilter={handleClearPeriodFilter}
                />
              </SuiBox>
            </SuiBox>

            <TableRequest data={tickets?.results} loading={loading} />
          </Card>
          {tickets && <PageListing data={tickets} />}
        </SuiBox>
      </DashboardLayout>
      <TicketUpdate />
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(UserTickets);
