// React core imports
import { useEffect, useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminCampaigns } from "@sharecode/common/redux/actions/campaignsActions";
import { updateToken } from "@sharecode/common/redux/actions/authActions";

// Third-party libraries
import moment from "moment";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React base components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import SimpleStatisticsCard from "examples/Cards/StatisticsCards/SimpleStatisticsCard";
import SimplePieChart from "examples/Charts/PieChart/simplePieChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import MixedChart from "examples/Charts/MixedChart";
import SimpleComplexReportsDoughnutChart from "examples/Charts/DoughnutCharts/SimpleComplexReportsDoughnutChart";

// Custom services
import { KpiService } from "@sharecode/common/services/KpiService";

// Custom components
import { DatePeriodFilter } from "components/Filters/DatePeriodFilter";

// Data fetching hooks
import useSimplePieChartData from "./data/simplePieChartData";
import useComplexSimpleDoughnutChart from "./data/complexReportsDoughnutChartData";
import useVerticalBarChart from "./data/verticalBarChartData";
import useScanAndEtickets from "./data/scan&eticketChartData";
import { ChartService } from "@sharecode/common/services";
import { periodicityOptions, servicesType } from "./constants";
import SuiSelect from "components/SuiSelect";

function DashboardAdmin() {
  const dispatch = useDispatch();

  const [formattedToken, setFormattedToken] = useState("");
  const campaigns = useSelector((state) => state.campaignReducer.campaigns);

  // access the token to update it on UAT
  const token = useSelector((state) => state?.campaignReducer?.token);

  // Update formattedToken whenever token changes
  useEffect(() => {
    const newFormattedToken = token?.split(" ")[1];
    if (newFormattedToken) {
      setFormattedToken(newFormattedToken);
    }
  }, [token]);
  // access the user id following successful login
  const userId = useSelector((state) => state?.auth.user);

  // update token on UAT
  useEffect(() => {
    if (userId && formattedToken && !window.location.hostname.includes("dash.app-check.fr")) {
      dispatch(updateToken(userId, formattedToken));
    }
  }, [formattedToken, userId, dispatch]);

  const [loading, setLoading] = useState(false);

  //date filters states
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [periodicity, setPeriodicity] = useState({ value: "day", label: "Jour" });
  const [periodicityValue, setPeriodicityValue] = useState("day");

  //data states
  const [listKpi, setListKpi] = useState([]);
  const [chartNbNewUsersData, setChartNbNewUsersData] = useState({ labels: [], data: [] });
  const [chartTicketDistributionData, setChartTicketDistributionData] = useState({
    labels: [],
    data: [],
  });
  const [chartNbNDFData, setChartNbNDFData] = useState({
    labels: [],
    data: [],
  });
  const [chartNbLoyaltyCardsData, setChartNbLoyaltyCardsData] = useState({
    labels: [],
    data: [],
  });
  const [chartNbCommentsData, setChartNbCommentsData] = useState({
    labels: [],
    datasets: {
      backgroundColors: [],
      data: [],
    },
  });
  const [chartAreasDistribution, setChartAreasDistribution] = useState({
    labels: [],
    datasets: {
      backgroundColors: [],
      data: [],
    },
  });
  const [countUsers, setCountUsers] = useState("-");
  const [countProUsers, setCountProUsers] = useState("-");

  const simplePieChartData = useSimplePieChartData();
  const complexReportsDoughnutChartData = useComplexSimpleDoughnutChart();
  const verticalBarChartData = useVerticalBarChart();
  const scanAndeticketsData = useScanAndEtickets();

  // fetching campaigns
  useEffect(() => {
    setLoading(true);
    dispatch(fetchAdminCampaigns())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching campaigns:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setStartDate(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));

    // KPIs & charts not impacted by any filter
    getTicketsPerArea();
    getCountUsers();
    getCountProUsers();
    getNbCommentsChartData();
  }, []);

  useEffect(() => {
    setPeriodicityValue(periodicity?.value || "day");
  }, [periodicity]);

  useEffect(() => {
    // KPIs & charts impacted by dates & periodicity
    if ((startDate && endDate) || (!startDate && !endDate)) {
      getNbNewUsersChartData();
      getTicketsDistributionChartData();
      getNbNDFChartData();
      getNbLoyaltyCardsChartData();
    }
  }, [periodicityValue, startDate, endDate]);

  useEffect(() => {
    // KPIs & charts only impacted by dates
    if ((startDate && endDate) || (!startDate && !endDate)) {
      getKpiStatsChartData();
    }
  }, [startDate, endDate]);

  // const handlePeriodFilter = () => {
  //   getKpiStatsChartData(startDateKPIs, endDateKPIs);
  //   getNbNewUsersChartData(startDateNewUsers, endDateNewUsers, periodicityNewUsers.value);
  // };

  const handleClearPeriodFilter = (service) => {
    setStartDate("");
    setEndDate("");
    // getKpiStatsChartData();
    // getNbNewUsersChartData();
  };

  const handleChangePeriodicity = (value) => {
    setPeriodicity(value);
    // getNbNewUsersChartData(startDateNewUsers, endDateNewUsers, value.value);
  };

  const getKpiStatsChartData = () => {
    KpiService.getKpiStats(startDate, endDate).then((response) => {
      setListKpi(response);
    });
  };

  const getNbNewUsersChartData = () => {
    // const startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
    // const endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

    ChartService.getNbNewUsers(startDate, endDate, periodicityValue).then((response) => {
      const newLabels = response.labels;
      const chart = {
        labels: newLabels,
        datasets: [{ color: "info", data: response.data }],
      };
      setChartNbNewUsersData(chart);
    });
  };

  const getTicketsDistributionChartData = () => {
    // const startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
    // const endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

    ChartService.getTicketsDistribution(startDate, endDate, periodicityValue).then((response) => {
      const newLabels = response.labels;
      const chart = {
        labels: newLabels,
        datasets: [
          {
            chartType: "thin-bar",
            color: "info",
            label: "Tickets photo",
            data: response.data_paper_tickets,
          },
          {
            chartType: "thin-bar",
            color: "warning",
            label: "Tickets mail",
            data: response.data_email_tickets,
          },
        ],
      };
      setChartTicketDistributionData(chart);
    });
  };

  const getNbNDFChartData = () => {
    // const startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
    // const endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

    ChartService.getNbNDF(startDate, endDate, periodicityValue).then((response) => {
      const newLabels = response.labels;
      const chart = {
        labels: newLabels,
        datasets: [{ color: "info", data: response.data }],
      };
      setChartNbNDFData(chart);
    });
  };

  const getNbLoyaltyCardsChartData = () => {
    ChartService.getNbLoyaltyCards(startDate, endDate, periodicityValue).then((response) => {
      const newLabels = response.labels;
      const chart = {
        labels: newLabels,
        datasets: [{ color: "info", data: response.data }],
      };
      setChartNbLoyaltyCardsData(chart);
    });
  };

  const getNbCommentsChartData = () => {
    ChartService.getNbComments().then((response) => {
      let availableColors = ["primary", "secondary", "info", "success", "warning", "error", "dark"];
      let backgroundColors = [];
      for (let i = 0; i < response.labels.length; i++) {
        const index = i % availableColors.length;
        backgroundColors.push(availableColors[index]);
      }
      const chart = {
        labels: response.labels,
        datasets: {
          label: "Secteurs",
          data: response.data,
          backgroundColors: backgroundColors,
        },
      };
      setChartNbCommentsData(chart);
    });
  };

  const getTicketsPerArea = () => {
    ChartService.getTicketsPerArea().then((response) => {
      let availableColors = ["primary", "secondary", "info", "success", "warning", "error", "dark"];
      let backgroundColors = [];
      for (let i = 0; i < response.labels.length; i++) {
        const index = i % availableColors.length;
        backgroundColors.push(availableColors[index]);
      }
      const chart = {
        labels: response.labels,
        datasets: {
          label: "Secteurs",
          data: response.data,
          backgroundColors: backgroundColors,
        },
      };
      setChartAreasDistribution(chart);
    });
  };

  const getCountUsers = () => {
    ChartService.getCountUsers().then((response) => {
      setCountUsers(response.total_users);
    });
  };

  const getCountProUsers = () => {
    ChartService.getCountProUsers().then((response) => {
      setCountProUsers(response.total_pro_users);
    });
  };

  return (
    <SuiBox py={4}>
      <SuiBox mb={2}>
        <DatePeriodFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClearPeriodFilter={() => handleClearPeriodFilter()}
          hideFilterButton={true}
        />
      </SuiBox>
      <SuiBox mb={2}>
        <Grid item xs={12} md={6} lg={3}>
          <SuiSelect
            placeholder="Sélectionnez la période"
            options={periodicityOptions}
            value={periodicity}
            onChange={(value) => handleChangePeriodicity(value)}
            xs={12}
            md={6}
          />
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleStatisticsCard
              bgColor="info"
              title={{ text: "Acquisition users", fontWeight: "medium" }}
              count={listKpi.count_new_users}
              icon={{ component: "group_icon" }}
              direction="left"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleStatisticsCard
              bgColor="info"
              title={{ text: "Nb de tickets", fontWeight: "medium" }}
              count={listKpi.count_tickets}
              icon={{ component: "confirmation_number_icon" }}
              direction="left"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleStatisticsCard
              bgColor="info"
              title={{ text: "Nb de NDF", fontWeight: "medium" }}
              count={listKpi.count_expense_reports}
              icon={{ component: "store_icon" }}
              direction="left"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <SimpleStatisticsCard
              bgColor="info"
              title={{ text: "Moy ticket par utilisateur", fontWeight: "medium" }}
              count={listKpi.count_average_number_of_tickets || 0}
              icon={{ component: "forest" }}
              direction="left"
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <GradientLineChart title="Nb de nouveaux utilisateurs" chart={chartNbNewUsersData} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MixedChart title="Répartition des tickets" chart={chartTicketDistributionData} />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <GradientLineChart title="Nb de notes de frais" chart={chartNbNDFData} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <GradientLineChart title="Nb de cartes de fidélité" chart={chartNbLoyaltyCardsData} />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SimpleComplexReportsDoughnutChart
              title="Tickets par secteurs"
              chart={chartAreasDistribution}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleComplexReportsDoughnutChart
              title="Avis client par secteur"
              chart={chartNbCommentsData}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SuiBox mb={2}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Nb total d'utilisateurs", fontWeight: "medium" }}
                count={countUsers}
                icon={{ component: "group_icon" }}
                direction="left"
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <SuiBox>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Nb d'utilisateurs pro", fontWeight: "medium" }}
                count={countProUsers}
                icon={{ component: "account_circle" }}
                direction="left"
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default DashboardAdmin;
