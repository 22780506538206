import axios from "axios";
import { TABLEDATA } from "./mocks";

import {
  URL_LIST_CREATE_COMMENTS,
  URL_GET_LIST_CLIENTS,
  SEND_COMMENT_TO_CLIENT,
  SEND_OFFER_TO_CLIENTS,
  getAuthorizationToken,
  parameterRequest,
  URL_POST_USER,
} from "./constantApi";

const get = () => {
  return new Promise((resolve, reject) => {
    resolve(TABLEDATA);
  });
};

const getListReviews = (page, size, search, cancelToken) => {
  var index = page
    ? `?${parameterRequest.page}=${page}`
    : `?${parameterRequest.page}=1`;
  var element = size
    ? `&${parameterRequest.size}=${size}`
    : `&${parameterRequest.size}=10`;
  var searchVal = search
    ? `&${parameterRequest.search}=${encodeURIComponent(search)}`
    : `&${parameterRequest.search}=`;
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(URL_LIST_CREATE_COMMENTS + index + element + searchVal, {
          headers: results.headers,
          cancelToken: cancelToken?.current?.token,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const getListClients = (page, size, search) => {
  var index = page
    ? `?${parameterRequest.page}=${page}`
    : `?${parameterRequest.page}=1`;
  var element = size
    ? `&${parameterRequest.size}=${size}`
    : `&${parameterRequest.size}=10`;
  var searchVal = search
    ? `&${parameterRequest.search}=${encodeURIComponent(search)}`
    : `&${parameterRequest.search}=`;
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        index: index,
        element: element,
        searchVal: searchVal,
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(
          URL_GET_LIST_CLIENTS +
            results.index +
            results.element +
            results.searchVal,
          {
            headers: results.headers,
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const getSingleClient = (userId) => {
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(URL_POST_USER + userId + "/", {
          headers: results.headers,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const replyToComment = (id, message) => {
  var data = {
    brand_answer: message,
  };
  var commentId = id;

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .patch(SEND_COMMENT_TO_CLIENT + commentId + "/", data, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const sendOffer = (offer) => {
  var offerToSend = offer;

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .post(SEND_OFFER_TO_CLIENTS, offerToSend, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

export const CheckService = {
  get,
  getListReviews,
  getListClients,
  getSingleClient,
  replyToComment,
  sendOffer,
};
