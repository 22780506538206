/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// redux
import { useDispatch, useSelector, connect } from "react-redux";
import { logout } from "@sharecode/common/redux/actions/auth";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import AccountCircle from "@mui/icons-material/AccountCircle";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setTransparentNavbar, setMiniSidenav } from "context";
import SuiAvatar from "components/SuiAvatar";
import Logo from "assets/images/logos/zara.png";

import { Oval } from "@agney/react-loading";
import { PROFILE } from "@sharecode/common/routes/constants";
import { getUserInfos } from "@sharecode/common/services/constantApi";
import { useBreadcrumbValue } from "routes/utils";

function DashboardNavbar(props) {
  const { absolute, light, isMini, auth } = props;
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatchController] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userInfo = getUserInfos();

  const breadLabel = useBreadcrumbValue();

  useEffect(() => {
    // Setting the navbar type

    if (fixedNavbar) {
      setNavbarType("static");
    }
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatchController,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }
    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatchController, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatchController, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatchController, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const logOut = () => {
    setLoading(true);
    // FCMService.unregisterDevice();
    setTimeout(() => {
      dispatch(logout());
    }, 500);
  };

  const profilMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      <Link to={PROFILE}>
        <MenuItem>
          <ListItemIcon>
            <AccountCircle color={light ? "white" : "dark"} fontSize="small" />
          </ListItemIcon>
          <SuiTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
            Profil
          </SuiTypography>
        </MenuItem>
      </Link>
      <Divider />
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="30" color="info" />
        </SuiBox>
      ) : (
        <MenuItem>
          <ListItemIcon>
            <Logout color={light ? "white" : "dark"} fontSize="small" />
          </ListItemIcon>

          <SuiTypography
            variant="button"
            fontWeight="medium"
            color={light ? "white" : "dark"}
            onClick={logOut}
          >
            Déconnexion
          </SuiTypography>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={breadLabel} route={route} light={light} />
        </SuiBox>
        {isMini ? null : (
          <SuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SuiBox pr={2} display="flex" alignContent="center">
              <SuiBox component="img" src={userInfo.brand?.logo} height="30px" />
            </SuiBox>
            <SuiBox pr={1} lineHeight={0.125}>
              <SuiTypography
                display="block"
                variant="button"
                fontWeight="medium"
                fontSize="medium"
                color={light ? "white" : "dark"}
              >
                {userInfo.first_name} {userInfo.last_name}
              </SuiTypography>
              {userInfo.brand?.name && (
                <SuiTypography variant="button" fontWeight="regular" fontSize="small">
                  {userInfo.brand?.name}
                </SuiTypography>
              )}
            </SuiBox>
            <SuiBox color={light ? "white" : "inherit"}>
              <IconButton color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
                <Icon fontSize="medium" className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton sx={[navbarIconButton, { cursor: "initial" }]}>
                {!auth.picture ? (
                  <Icon
                    fontSize="medium"
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    account_circle
                  </Icon>
                ) : (
                  <SuiAvatar src={auth.picture} alt="Avatar" size="sm" variant="rounded" />
                )}
              </IconButton>

              <IconButton sx={navbarIconButton} onClick={handleOpenMenu}>
                <Icon
                  fontSize="medium"
                  sx={({ palette: { dark, white } }) => ({
                    color: light ? white.main : dark.main,
                  })}
                >
                  settings
                </Icon>
              </IconButton>
              {profilMenu()}
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of dNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the dNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(DashboardNavbar);
