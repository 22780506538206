//React and libs
import { useState } from "react";
import { connect } from "react-redux";

// Material UI
import Card from "@mui/material/Card";

// SUI
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiTypography from "components/SuiTypography";

//Components
import TableRequest from "./components/TableRequest";

import { ExportButton } from "components/ExportButton";

function ManageOffersAdmin(props) {
  const [loadingExport, setLoadingExport] = useState(false);

  //TODO: Add the correct URL
  // const handleCampaignsExport = () => {
  //   setLoadingExport(true);

  //   const keys = ["search"];

  //   const paramsValues = {};

  //   keys.forEach((key) => {
  //     const value = searchParams.get(key);
  //     if (value) {
  //       paramsValues[key] = value;
  //     }
  //   });

  //   exportTable({ url: URL_EXPORT_USERS, fileName: "users.xlsx" }, paramsValues)
  //     .then((response) => {})
  //     .catch((error) => {
  //       disp(setSnackbar(true, "error", "error", "No entry to export"));
  //     })
  //     .finally(() => {
  //       setLoadingExport(false);
  //     });
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={2}>
        <Card>
          <SuiBox
            p={2}
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            gap={2}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <SuiTypography variant="h6">Liste de mes campagnes</SuiTypography>
            <SuiBox
              display="flex"
              gap={2}
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
              }}
            >
              <ExportButton disabled />
            </SuiBox>
          </SuiBox>
          <SuiBox
            p={2}
            display="flex"
            justifyContent="space-between"
            gap={4}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          ></SuiBox>
          <TableRequest />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ManageOffersAdmin);
