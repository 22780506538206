import { useRole } from "./ProtectedRoutes";
import {
  IS_ADMIN_VALUE,
  IS_MANAGER_BRAND_VALUE,
  IS_EMPLOYEE_BRAND_VALUE,
  IS_EMPLOYEE_SHOP_VALUE,
  IS_MANAGER_SHOP_VALUE,
} from "@sharecode/common/redux/actions/actionTypes";
import {
  IS_ADMIN,
  IS_MANAGER_BRAND,
  IS_MANAGER_SHOP,
} from "@sharecode/common/redux/actions/actionTypes";
import { useLocation } from "react-router-dom";
import {
  BRAND_LIST,
  CLIENTS_EXPERIENCES,
  CLIENTS_LIST,
  CREATE_OFFER,
  DASHBOARD,
  CHAT,
  MANAGE_USERS,
  NEW_PASSWORD,
  PROFILE,
  RESET_PASSWORD,
  SHOP_LIST,
  SIGN_IN,
  WAIT_PAGE_SOCIAL_LINKEDIN,
  WAIT_PAGE_SOCIAL_GOOGLE,
  SIGN_UP,
  SIGN_UP_ACTIVATION,
  NOT_FOUND,
  TICKET_LIST,
  REVIEWS_LIST,
  USER_TICKET_LIST,
  BRAND_TICKET_LIST,
  SECTOR_LIST,
  CREATE_STORY,
} from "@sharecode/common/routes/constants";

const allRoutes = [
  {
    name: "Profil",
    key: "profile",
    route: PROFILE,
  },
  {
    name: "Experiences clients",
    key: "experiences-clients",
    route: CLIENTS_EXPERIENCES,
  },
  {
    name: "Liste avis clients",
    key: "reviews-list",
    route: REVIEWS_LIST,
  },
  {
    name: "Liste des secteurs",
    key: "sector-list",
    route: SECTOR_LIST,
  },
  {
    name: "Liste clients",
    key: "listes-clients",
    route: CLIENTS_LIST,
  },
  {
    name: "Chat",
    key: "chat",
    route: CHAT,
  },
  {
    name: "Create offer",
    key: "create-offer",
    route: CREATE_OFFER,
  },
  {
    name: "Create Story",
    key: "create-story",
    route: CREATE_STORY,
  },
  ,
  {
    name: "Gestion utilisateurs",
    key: "manage-users",
    route: MANAGE_USERS,
  },
  {
    name: "Liste enseignes",
    key: "brand-list",
    route: BRAND_LIST,
  },
  {
    name: "Dashboard",
    key: "dashboard",
    route: DASHBOARD,
  },
  // {
  //   name: "Liste points de vente",
  //   key: "shop-list",
  //   route: SHOP_LIST,
  // },
  {
    name: "Liste tickets",
    key: "ticket-list",
    route: TICKET_LIST,
  },
  {
    name: "Liste des tickets utilisateurs",
    key: "user-tickets-list",
    route: USER_TICKET_LIST,
  },
  {
    name: "Liste des tickets enseigne",
    key: "brand-tickets-list",
    route: BRAND_TICKET_LIST,
  },
  {
    name: "Créer une offre",
    key: "create-offer",
    route: CREATE_OFFER,
  },
  {
    name: "Se connecter",
    key: "sign-in",
    route: SIGN_IN,
  },
  {
    name: "S'inscrire avec Linkedin",
    key: "/accounts/login/linkedin/callback/",
    route: WAIT_PAGE_SOCIAL_LINKEDIN,
  },
  {
    name: "S'inscrire avec Google",
    key: "/accounts/login/google/callback/",
    route: WAIT_PAGE_SOCIAL_GOOGLE,
  },
  {
    name: "Inscription",
    key: "sign-up",
    route: SIGN_UP,
  },
  {
    name: "Activer mon inscription",
    key: "sign-up-activation",
    route: SIGN_UP_ACTIVATION,
  },
  {
    name: "Nouveau mot de passe",
    key: "new-password",
    route: NEW_PASSWORD,
  },
  {
    name: "Réinitialiser mon mot de passe",
    key: "reset-password",
    route: RESET_PASSWORD,
  },
  {
    name: "Page Introuvable",
    key: "not-found",
    route: NOT_FOUND,
  },
];

export const useBreadcrumbValue = () => {
  const actualRoute = useLocation().pathname.split("/").slice(1);

  let searchedRoute = "";

  allRoutes.map((item) => {
    if (actualRoute.toString() == item.key) {
      searchedRoute = item.name;
    } else {
      return "Page Introuvable";
    }
  });

  return searchedRoute;
  // allRoutes.map((item) => {
  //   if (actualRoute && item.key == actualRoute) {
  //     const searchedRoute = allRoutes.filter((item) => item.route === "/" + actualRoute[0]);
  //     return searchedRoute[0].name;
  //   } else {
  //     return "Page Introuvable";
  //   }
  // });
};
